import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../../components/global/Layout"
import Form from "../../components/templates/Form"
import { PortableText } from "@portabletext/react"
import Seo from "../../components/global/Seo"

export default function Listing({ data }) {
    const frontMatter = data.sanityListing
    return (
        <>
            <Layout>
                <Seo
                    title={frontMatter?.seo?.title_tag}
                    description={frontMatter?.seo?.meta_description}
                />
                <div className="section">
                    <div className="container">
                        <div className="md:flex gap-10">
                            <div className="md:w-3/5">
                                <div>
                                    <h1 className="text-3xl font-medium">{frontMatter.title}</h1>
                                    {frontMatter?.area && <span>{frontMatter.area} </span>}
                                </div>
                                <div className="mt-5 border p-4 bg-gray-100">
                                    <div className="grid grid-cols-3">
                                        {frontMatter.annual_gross &&
                                            <div>
                                                <h3 className="text-md">Annual Gross</h3>
                                                <span className="font-bold">{frontMatter.annual_gross}</span>
                                            </div>
                                        }
                                        {frontMatter.price &&
                                            <div>
                                                <h3 className="text-md">Asking Price</h3>
                                                <span className="font-bold">{frontMatter.price}</span>
                                            </div>
                                        }
                                        {frontMatter.status &&
                                            <div>
                                                <h3 className="text-md">Status</h3>
                                                <span className="font-bold">{frontMatter.status}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="mt-5 border p-4">
                                    <h2 className="font-medium text-xl">Description</h2>
                                    <div className="md-content">
                                        {frontMatter._rawDescription &&
                                            <PortableText
                                                value={frontMatter._rawDescription}
                                            />
                                        }
                                    </div>
                                    <div className="my-5">
                                        <p className="font-bold">Please contact us to receive the confidentiality agreement (NDA Non Disclosure Agreement) for any of the above practices for sale</p>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-2/5">
                                <div>
                                    <h2 className="font-medium text-xl">Inquire about this practice</h2>
                                    <Form
                                        formName={`Listing Inquiry - ${frontMatter.title}`}
                                        subject={`Listing Inquiry - ${frontMatter.title}`}
                                        source={`https://www.cpabuysell.com/listings/` + frontMatter._id}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export const query = graphql`
query ($id: String) {
    sanityListing(id: {eq: $id}) {
      title
      _id
      annual_gross
      area
      price
      seo {
        meta_description
        title_tag
      }
      status
      _rawDescription(resolveReferences: {maxDepth: 5})
    }
  }
  
  
`